import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Proj from "../components/proj"
import * as styles from "../components/index.module.css"
import { Divider } from '@chakra-ui/react'
import "@fontsource/ibm-plex-mono"
import "@fontsource/ibm-plex-serif"
import ProductSimple from "../components/ProductSimple"
import { motion as m } from "framer-motion";


const links = [
  {
    text: "Projects",
    url: "/page-2",
    description:
      "Brief description of the projects I've worked on. Send a request to get acces to a private repository",
  },
  {
    text: "How to Spell my last name?",
    url: "/forfun",
    description: "A fun page to learn how to spell my last name in 4 simple steps",
  }
  // {
  //   text: "Something Here",
  //   url: "/",
  //   description:"Not sure what to ad at the moment, will do something",
  // },
  // {
  //   text: "Maybe a picture or something here",
    
    
  // },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <m.div initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 0.5, ease:"easeOut" }}>
  <Layout
  backgroundColor='white'>
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/icon.png"
        loading="eager"
        width={250}
        quality={100}
        formats={["auto", "webp", "avif"]}
        alt="logo photo"
        style={{ marginBottom: 0 , borderRadius:"100px"}}
      />

      <h2 style={{ marginBottom: 50 }}>

      </h2>
      <div >
      <h1>
        {/* <b style={{padding:30,
          color:"white",
          borderRadius: 7,
          backgroundColor: `var(--color-light-pink)`
          }}>ANU LKHAGVASUREN</b> */}
      </h1>
      </div>
      <p 
      
      >Scroll below to learn about my interests, some of the projects I worked on (but will be edited in the future), as well as   
        <a href="/forfun"> learn how to spell my last name  </a>
        if you're interested!
        <br />
      </p>
      <Proj />
      <br />
      <Divider orientation='horizontal' />
    </div>
    <ul className={styles.list}>
      {links.map(link => (
        <li key={link.url} className={styles.listItem}>
          <a
            className={styles.listItemLink}
            href={`${link.url}${utmParameters}`}
          >
            {link.text} ↗
          </a>
          <p className={styles.listItemDescription}>{link.description}</p>
        </li>
      ))}
    </ul>
    <ProductSimple/>
    <br/>
    <br/>
    <Divider orientation='horizontal' />
    <h3>Got Any Feedback?</h3>
    Fill out the anonymous form below!
    <br/>
    <br/>
    <Link to="/Feedback" style={{padding:7, backgroundColor: "bisque", borderRadius:6, margin:0}}>
    Give Feedback
    </Link>
  </Layout>
  </ m.div>
)

export const Head = () => <Seo title="Anu Lkhagvasuren's Portfolio" />

export default IndexPage
