import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Badge,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue, EmailIcon
} from '@chakra-ui/react';
import Proj from "./proj";

export default function ProductSimple() {
  return (
    <Center py={2}>
      <Stack
        borderWidth="1px"
        borderRadius="lg"
        w={{ sm: '100%', md: '540px' }}
        height={{ sm: '476px', md: '20rem' }}
        direction={{ base: 'column', md: 'row' }}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'xl'}
        padding={18}>
        <Flex flex={0.5} bg="white">
        <StaticImage
        src="../images/anuka.png.png"
        loading="eager"
        width={200}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: 0 }}
      />
          {/* <Image
            boxSize="99%"
            position="center"
            fit="cover"
            src="../images/LOG.png"
            alt="supposed to be a pic of me"
          /> */}
        </Flex>
        <Stack
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={1}
          pt={2}>
          <Heading fontSize={'2xl'} fontFamily={'body'}>
            Currently I'm: ...
          </Heading>
          <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
            @anuu_l
          </Text>
          <Text
            textAlign={'center'}
            color={useColorModeValue('gray.700', 'gray.400')}
            px={3}>
            📍 Location: Bay Area, CA
            <br/>
            🐻 University : UC Berkeley
            <br/>
            👩‍🎓 Major: Data Science and Environmental Economics
          </Text>
          <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
            <Badge
              px={2}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.800')}
              fontWeight={'400'}>
              #skiing
            </Badge>
            <Badge
              px={2}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.800')}
              fontWeight={'400'}>
              #spicysnacks
            </Badge>
            <Badge
              px={2}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.800')}
              fontWeight={'400'}>
              #music
            </Badge>
          </Stack>

          <Stack
            width={'100%'}
            mt={'2rem'}
            direction={'row'}
            padding={2}
            justifyContent={'space-between'}
            alignItems={'center'}>
          </Stack>
        </Stack>
      </Stack>
    </Center>
  );
}